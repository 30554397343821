.scroll-nav {
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.scroll-nav {
  position: relative;
  padding: 0;
  box-sizing: border-box;
}

.scroll-nav-container {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  position: relative;
  font-size: 0;
  text-align: center;
}

.scroll-nav-container {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scroll-nav-container::-webkit-scrollbar {
  display: none;
}

.scroll-nav__nav-list {
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  position: relative;
  list-style: none;
  display: inline-block;
  margin: auto;
  margin: 0;
  padding: 0;
}

.scroll-nav__nav-list--no-transition {
  -webkit-transition: none;
  transition: none;
}

.scroll-nav__nav-item {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  padding: 0;
  margin: 0 7px 0 0;

  a {
    display: inline-block;
    padding: 19px 6px 15px;
    text-transform: uppercase;
    text-decoration: none;
    color: #212121;
    font-size: 14px;
    font-family: $font-family-roboto;
    font-weight: normal;
    @include respond-to(xs) {
      font-size: 12px;
      padding: 15px 5px 12px;
    }
  }
}

.scroll-nav__nav-item + .scroll-nav__nav-item {
  margin: 0 7px;
}

.scroll-nav__nav-item[aria-selected="true"] {
  a {
    color: #000;
  }
}

.scroll-nav__scroller {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;

  &:focus {
    outline: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &--left {
    left: 0;
    padding: 10px 8px 0 5px;
    background: linear-gradient(to right, rgba(255, 255, 2555, 1) 0%, rgba(255, 255, 255, 1) 16%, rgba(255, 255, 255, 0.8) 67%, rgba(255, 255, 255, 0) 100%);
  }

  &--right {
    right: 0;
    padding: 10px 5px 0 8px;
    background: linear-gradient(to left, rgba(255, 255, 2555, 1) 0%, rgba(255, 255, 255, 1) 16%, rgba(255, 255, 255, 0.8) 67%, rgba(255, 255, 255, 0) 100%);
  }
}

[data-overflowing="both"] ~ .scroll-nav__scroller--left, [data-overflowing="left"] ~ .scroll-nav__scroller--left {
  opacity: 1;
}

[data-overflowing="both"] ~ .scroll-nav__scroller--right, [data-overflowing="right"] ~ .scroll-nav__scroller--right {
  opacity: 1;
}

.scroll-nav__scroller__icon {
  width: auto;
  height: 20px;
  fill: #ccc;
}

.scroll-nav-indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100px;
  background-color: transparent;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: background-color .2s ease-in-out, -webkit-transform .2s ease-in-out;
  transition: background-color .2s ease-in-out, -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out, background-color .2s ease-in-out;
  transition: transform .2s ease-in-out, background-color .2s ease-in-out, -webkit-transform .2s ease-in-out;
}