.onboarding-progress {
  white-space: normal !important;
  &__progressbar {
    position: relative;
    height: 12px;
    margin-top: 5px;
    background: #e5e5e5;
    border-radius: 8px;
  }
  &__completed {
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    background: #4d92df;
    border-radius: 6px;
  }
}
