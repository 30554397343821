.premium-search-form {
  line-height: 1;
  &__label {
    color: #959595;
    text-transform: uppercase;
    margin: 12px 0 5px 0;
    font-weight: 400;
    padding: 5px 0 5px;
    font-size: 12px;
  }
  &__footer {
    margin-top: 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__tagline {
    padding-right: 15px;
    font-size: 12px;
  }
  @include respond-to(xs) {
    &__footer {
      margin-top: 27px;
      display: block;
    }
    &__tagline {
      display: none;
    }
  }
  &__btn {
    font-size: 17px;
    font-weight: 500;
    padding: 8px 20px;
  }
  .premium-search-form-select {
    &--area,
    &--country {
      width: 47.5%;
      float: left;
    }
    &--area {
      margin-left: 5%;
    }
  }
}

.slider-container {
  height: 60px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.overlay-div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
  display: none;
  opacity: 1;
}

.overlay-div.visible {
  display: block;
}
