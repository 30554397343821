.banner {
  //min-width: $container-large-desktop;
  position: relative;

  .back-to {
    display: block;
    margin-bottom: 5px;
    color: #fff;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400;
    text-decoration: none;

    .glyphicon {
      font-size: 12px;
    }
  }

  .carousel {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 200px;
    min-height: 200px;

    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      top: auto;

      //background: linear-gradient(0deg, rgba(255,252,0,1) 50%, rgba(0,121,255,1) 50%);
      //opacity: 0.3;

      background-color: transparent;
      background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8));
      opacity: 1;
      @include respond-to(xs) {
        opacity: 1;
      }
    }

    .item {
      display: inline-block;
      height: 200px;
      min-height: 200px;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    @include respond-to(sm) {
      height: 200px;
      .item {
        height: 200px;
      }
    }
    @include respond-to(xs) {
      height: 150px;
      .item {
        height: 150px;
      }
    }
    @include respond-to(xs) {
      min-height: 150px;
    }

    &.no-photo {
      .item {
        background-image: url("#{$image_path}foggy-mountains-fullhd.jpg");
      }
    }
  }

  .banner-placeholder {
    position: absolute;
    top: auto;
    bottom: 20px;
    width: 100%;
    text-align: left;

    @include respond-to(sm) {
    }
    @include respond-to(xs) {
      top: auto;
      bottom: 10%;
    }

    .container {
      position: relative;
    }

    .banner-placeholder-header,
    h1 {
      font-size: 50px;
      height: 50px * 1.5;
      line-height: 1.5;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      font-weight: 500;
      color: #fff;
      text-transform: none;
      font-family: $font-family-roboto;
      margin: 0;
      padding: 0;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      @include respond-to(lg) {
        // width: 700px;
      }
      @include respond-to(md) {
        // width: 550px;
      }
      @include respond-to(sm) {
        font-size: 38px;
        height: 55px;
        line-height: 55px;
        // width: 400px;
      }
      @include respond-to(xs) {
        font-size: 28px;
        height: 40px;
        line-height: 40px;
      }
    }

    .location {
      color: #fff;
      font-family: $font-family-roboto;
      padding: 0;
      font-size: 16px;
      text-transform: none;
      margin: 0px 0 15px 0;
      font-weight: 300;
      border-bottom: none;

      /*@include respond-to(sm) {
        font-size: 18px;
        margin: 5px 0 25px 0;
      }*/
      @include respond-to(xs) {
        font-size: 13px;
        margin: 0px 0 10px 0;
      }

      a {
        color: #fff;
        font-style: italic;
      }

      .maintainedby {
        //display: none;
        display: block;
        font-style: normal;
        opacity: 0.8;

        a {
          font-style: normal !important;
        }

        font-weight: 500;
      }
    }

    .crag-description {
      margin-top: 15px;
      color: #fff;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    .info-container {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      line-height: 16px;
      font-size: 16px;
      font-weight: 400;
      gap: 10px;

      a {
        color: #fff;
      }

      .glyphicon {
        font-size: 16px;
        margin-right: 5px;
      }

      .like-container {
        display: flex;

        .btn-like {
          color: #fff;
        }

        .likes {
          font-weight: 500;
        }
      }

      .area-container {
        display: flex;

        a {
          color: #fff;
        }
      }

      @include respond-to(xs) {
        font-size: 13px;
        line-height: 13px;
        .glyphicon {
          font-size: 13px;
        }
      }
    }

    .add-to-favourites {
      position: absolute;
      right: 15px;
      bottom: 20px;
    }

    @include respond-to(xs) {
      .craglocation {
        margin-bottom: 10px;
      }
      .add-to-favourites {
        position: relative;
        right: auto;
        bottom: auto;
      }
    }
  }

  .commercial {
    position: absolute;
    bottom: 55px;
    right: 15px;
    width: 400px;
    @include respond-to(md) {
      width: 350px;
    }
    @include respond-to(sm) {
      width: 300px;
    }
    @include respond-to(xs) {
      display: none;
    }
  }

  &--simple {
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .banner-content {
      padding: 30px 15px;
      color: #fff;

      h1 {
        font-size: 38px;
        font-family: $font-family-roboto;
        font-weight: 500;
        margin: 0;
        padding: 0;
        line-height: 1.1;
        text-transform: none;
        text-align: center;

        .pretag {
          display: block;
          font-size: 50%;
        }
      }

      h2 {
        font-size: 18px;
        font-family: $font-family-roboto;
        margin: 20px 0;
        line-height: 1.5;
        padding: 0 30px;
        text-transform: none;
        text-align: center;

        .premium {
          font-size: 100%;
          color: #fff;
        }
      }

      @include respond-to(md) {
        h1 {
          font-size: 38px;
        }
        h2 {
          margin: 15px 0 20px 0;
          font-size: 16px;
        }
      }
      @include respond-to(sm) {
        h1 {
          font-size: 28px;
        }
        h2 {
          margin: 15px 0 20px 0;
          font-size: 16px;
        }
      }
      @include respond-to(xs) {
        h1 {
          font-size: 24px;
        }
        h2 {
          margin: 10px 0 15px 0;
          font-size: 14px;
          padding: 0;
        }
      }
    }
  }

  &--subpage {
    .banner-placeholder {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      h1 {
        font-size: 34px;
        height: auto; //1.5 * 34px;
        font-weight: 700;
        padding-top: 20px;

        @include respond-to(xs) {
          font-size: 26px;
        }
      }

      p {
        font-style: normal;
        font-size: 14px;
        line-height: 150%;
        font-weight: 400;
        color: #fff;
        /* css ellipsis */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;

        a {
          color: #fff;
          text-decoration: none;
          font-weight: 500;
        }

        @include respond-to(xs) {
          font-size: 12px;
          font-weight: 400;
          -webkit-line-clamp: 2; /* number of lines to show */
        }
      }
    }
  }

  &--fill-screen {
    position: fixed;
    height: 100%;
    width: 100%;

    .carousel {
      height: 100% !important;
      width: 100%;

      .item {
        height: 100% !important;
        width: 100%;
      }
    }

    .banner-placeholder {
      top: 0px !important;
      bottom: auto;
    }
  }
}

.banner.user-profile {
  .carousel {
    height: 300px;
    min-height: 300px;

    .item {
      height: 300px;
      min-height: 300px;
    }

    .overlay {
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
      opacity: 1;
      @include respond-to(xs) {
        opacity: 1;
      }
    }

    &.no-photo {
      .item {
        background-image: url("#{$image_path}foggy-mountains-fullhd.jpg");
      }
    }
  }

  .banner-placeholder {
    top: 50%;
    bottom: auto;
    margin-top: -75px;
    @include respond-to(xs) {
      top: auto;
      bottom: 10%;
    }

    .climber-photo {
      float: left;

      img {
        border: 4px solid #fff;
        height: 155px;
        width: 155px;
      }

      @include respond-to(xs) {
        float: none;
        text-align: center;
        img {
          height: 65px;
          width: 65px;
          border: 2px solid #fff;
        }
      }
    }

    .climber-photo + .climber-details {
      @include respond-to(xs) {
        margin: 0;
      }
    }

    .climber-banner-content {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      flex-direction: row;
      gap: 20px;
      @include respond-to(xs) {
        flex-direction: column;
      }
    }

    .climber-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: flex-start;

      @include respond-to(xs) {
        text-align: center;
        margin: 10px 0 0 0;
      }
    }

    h1 {
      font-size: 34px;
      height: auto; //1.5 * 34px;
      font-weight: 400;

      @include respond-to(xs) {
        font-size: 26px;
      }

      .climber-name {
        text-transform: none;
        font-weight: 700;
      }
    }

    .climber-description {
      font-style: normal;
      font-size: 14px;
      line-height: 150%;
      font-weight: 400;
      color: #fff;
      /* css ellipsis */
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
      @include respond-to(xs) {
        -webkit-line-clamp: 2; /* number of lines to show */
      }

      a {
        color: #fff;
        text-decoration: none;
        font-weight: 500;
      }

      @include respond-to(xs) {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .climber-badges {
      .badge {
        background-color: $premium-tag-bg;
        padding: 5px 10px;
        border-radius: 2px;

        .premium {
          font-size: 12px;
          font-weight: 400;
          color: #000;
          text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
        }
      }
    }

    .description-follow-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: flex-start;
      gap: 20px;

      @include respond-to(sm) {
        gap: 10px;
      }
      @include respond-to(xs) {
        flex-direction: column;
        gap: 10px;
      }
    }

    .climber-follow-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-content: flex-start;
      gap: 20px;

      @include respond-to(lg) {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 200px;
      }
      @include respond-to(md) {
      }
      @include respond-to(sm) {
        gap: 10px;
      }
      @include respond-to(xs) {
        gap: 10px;
        justify-content: center;
        align-items: center;
      }

      .followers {
      }

      .following {
      }

      .follow {
        @include respond-to(sm) {
          margin-top: 10px;
        }
      }
    }
  }
}

.crag-sharetools {
  .copytoclipboard {
    display: none;
  }

  color: #fff;
  font-size: 14px;
  line-height: 18px;

  a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    display: inline-block;
    width: 20px;
    height: 18px;
    margin-right: 3px;

    &:hover {
      color: $brand-primary;
    }
  }

  .like-container {
    display: inline-block;
    margin-right: 5px;

    .likes {
      display: inline-block;
      font-weight: 500;
      height: 20px;
      line-height: 14px;
      font-size: 12px;
      vertical-align: middle;
    }
  }
}

#teams-index {
  .banner .banner-placeholder .description {
    color: #fff;
    display: block;
    font-size: 16px;
    font-family: $font-family-roboto;
    margin-bottom: 17px;
    text-transform: uppercase;
  }
}

.banner.banner--large,
#crags-show .banner {
  //box-shadow: 1px 0px 5px #000;
  .back-to {
    display: none;
  }

  .carousel {
    height: 650px;

    .item {
      height: 650px;
    }

    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      top: auto;
      left: 0;
      //background: linear-gradient(0deg, rgba(255,252,0,1) 50%, rgba(0,121,255,1) 50%);
      //opacity: 0.3;
      background-color: transparent;
      background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4));
      opacity: 1;
      @include respond-to(xs) {
        opacity: 1;
      }
    }

    @include respond-to(sm) {
      height: 450px;
      .item {
        height: 450px;
      }
    }
    @include respond-to(xs) {
      height: 350px;
      .item {
        height: 350px;
      }
    }
  }

  .banner-placeholder {
    top: 40%;
    width: 100%;
    bottom: auto;
    text-align: center;

    .container {
      display: inline-block;
      max-width: 100%;
      width: 600px;
    }

    h1 {
      //max-width: 500px;
      font-size: 50px;
      font-weight: 700;
      line-height: 1.2;
      height: 50px * 1.2;
      width: auto !important;

      @include respond-to(sm) {
        font-size: 46px;
        height: 46px * 1.2;
      }
      @include respond-to(xs) {
        font-size: 28px;
        height: 28px * 1.2;
      }
    }

    h2 {
      font-size: 22px;
      text-transform: none;
      margin: 14px 0 0px 0;
      font-weight: 300;
      //padding-bottom: 15px;
      color: rgba(255, 255, 255, 0.8);
      //border-bottom: 1px solid ;

      @include respond-to(sm) {
        font-size: 18px;
        margin: 5px 0 0px 0;
      }
      @include respond-to(xs) {
        font-size: 13px;
        margin: 5px 0 0px 0;
      }

      a {
        color: #fff;
        font-style: italic;
      }

      .maintainedby {
        display: block;
        font-size: 18px;
        margin-top: 11px;
        color: #fff;

        a {
          font-style: normal !important;
        }

        @include respond-to(sm) {
          font-size: 14px;
        }
        @include respond-to(xs) {
          margin-top: 5px;
          font-size: 11px;
        }
      }
    }

    .hr {
      height: 1px;
      display: inline-block;
      width: 90px;
      border-top: 1px solid rgba(255, 255, 255, 0.8);
      background: transparent;
      //margin-top: 15px;
      //padding-bottom: 12px;
      margin-top: 7px;
      padding-bottom: 7px;
      @include respond-to(xs) {
        display: none;
      }
    }

    .crag-description {
      display: block;
      font-size: 16px;
      font-family: $font-family-roboto;
      font-style: italic;
      margin-bottom: 17px;

      .quote {
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 86px;
        display: block;
      }

      .writer {
        color: rgba(255, 255, 255, 0.85);

        a {
          color: rgba(255, 255, 255, 0.85);
        }
      }

      @include respond-to(sm) {
        font-size: 14px;
        .quote {
          font-size: 14px;
        }
      }
      @include respond-to(xs) {
        font-size: 12px;
        .quote {
          font-size: 12px;
        }
      }
    }

    .banner-placeholder__buttons_container {
      display: block;
      text-align: center;

      .btn {
        font-size: 16px;
        padding: 7px 13px;
        font-weight: 500;

        &.btn-transparent {
          border: 2px solid #fff;
          background-color: rgba(0, 0, 0, 0.1);
          color: #fff;
        }

        &.btn-primary {
          border: 2px solid $brand-primary;
          background: $brand-primary;
        }

        .glyphicon {
          display: inline-block;
          transform: translateY(4px);
          padding-right: 8px;
          font-size: 20px;
          line-height: 100%;
        }

        @include respond-to(xs) {
          font-size: 12px;
          padding: 5px 10px;
          .glyphicon {
            font-size: 14px;
            transform: translateY(2px);
            padding-right: 4px;
          }
        }
      }
    }

    .add-to-favourites {
      position: relative;
      //margin: 0;
      right: auto;
      bottom: auto;
      //position: relative;
      margin: 15px 10px;
      //right: auto;
      //bottom: auto;
      display: inline-block;

      .btn-favourites {
        margin: 0;
      }

      @include respond-to(xs) {
        margin: 7px 5px 4px 5px;
      }
    }
  }

  &.video-bg {
    .carousel {
      height: auto;
      margin-bottom: -10px;

      video.item {
        pointer-events: none;
        width: 100%;
        height: auto;
      }
    }

    @include respond-to(sm) {
      .banner-placeholder {
        top: 35%;
      }
    }
    @include respond-to(xs) {
      .banner-placeholder {
        top: 35%;

        .location {
          margin-bottom: 10px;
        }
      }
    }
  }
}

#site-create_topo,
#site-create_premium_topo {
  h1.crag-name {
    width: auto;
  }
}

/*#topos-show,
#topos-index {
  .banner {
    .carousel {
      min-height: 200px;
      .item {
        background: none !important;
        min-height: 200px;
      }
    }
    .banner-placeholder {
      top: 0;
      bottom: auto;
    }
  }
}*/
