.slider-fragment-label {
  margin-top: 43px;
  text-align: center;
  margin-left: 0;
}
.slider-fragment-label-cont {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: "none";
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.slider-fragment {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
  background-color: white;
}
.slider-fragment-bar {
  position: absolute;
  &:after {
    position: absolute;
    content: "";
    height: 15px;
    width: 1px;
    top: 8px;
    background: #9a9a9a;
  }
}
.slider-fragment-bar.first:after {
  left: 9px;
}
.slider-fragment-bar.second:after {
  left: 15px;
}
.slider-fragment-bar.third:after {
  left: 21px;
}

.inner-slider-rail {
  position: absolute;
  width: 100%;
  height: 3px;
  transform: translate(0%, -50%);
  border-radius: 1.5px;
  pointer-events: none;
  background-color: #e2e2e2;
}

.slider-track {
  position: absolute;
  transform: translate(0%, -50%);
  height: 3px;
  z-index: 1;
  background-color: #5998df;
  border-radius: 1.5;
  cursor: pointer;
}
