.items-list {
  &__container {
    padding: 10px 0;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    .climber-thumb {
      border: 2px solid #E4E4E4 !important;
    }
    .item-name,
    .climber-name {
      color: #333;
      font-weight: bold;
    }
    .date {
      color: #777777;
      * {
        color: #777777;
        text-decoration: none;
      }
    }
    .title,
    .item-description,
    .description {
      color: #333;
      a {
        color: #333;
        font-weight: 500;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__item {
    &--thumbnail {
      width: 50px;
      flex:0 0 auto;
    }
    &--details {
      flex:1 1 auto;
    }
    &--action {
      width: 95px;
      text-align: right;
      flex:0 0 auto;
    }
  }
  &--shrinked {
    .items-list__container {
      margin-bottom: 10px;
      padding: 0;
    }
  }
  &--bordered {
    .items-list__container {
      border-top: 1px solid #eeeeee;
      &:last-child {
        border-bottom: 1px solid #eeeeee;
      }
    }
  }
}