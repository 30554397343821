.photo {
  .premium-badge {
    font-weight: 500;
    padding: 3px 10px;
    border-radius: 12px 0 0 12px;
    font-size: 12px;
    display: inline-block;
    background-color: $brand-primary;
    color: #000;
    position: absolute;
    top: 10px;
    right: 0;
  }
}
.premium-badge {
  font-weight: 500;
  padding: 3px 10px;
  border-radius: 12px;
  font-size: 12px;
  display: inline-block;
  background-color: $brand-primary;
  color: #000;
  &--small {
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 10px;
  }
}
.free-badge {
  font-weight: 500;
  padding: 3px 10px;
  border-radius: 12px;
  font-size: 12px;
  display: inline-block;
  background-color: #333;
  color: #fff;
  &--small {
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 10px;
  }
}
