.routelist-container {
  .routelist__filter-buttons {
    text-align: center;
    padding-bottom: 15px;
    .dropdown--filter {
      margin: 5px 6px;
      button.btn {
        padding: 5px 10px;
      }
    }
  }
  .routelist-table-header {
    display: flex;
    justify-content: space-between;
  }
  .premium-search-filter__checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    padding: 10px 0 10px 0;
    .rating-star-container {
      width: 100%;
      display: flex;
      justify-content: space-around;
      font-size: 35px;
      .stars {
        cursor: pointer;
      }
    }
  }

  .premium-search__checkbox-item {
    margin-top: 5px;
    margin-right: 5px;
    list-style-type: none;
    position: relative;
    label {
      cursor: pointer;
      margin-bottom: 0;
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkbox-label {
      display: inline-block;
      margin: 0;
      color: #333333;
      font-size: 12px;
      font-weight: 400;
      vertical-align: middle;
      padding: 5px;
      .tags {
        display: inline-block;
        vertical-align: middle;

        .tag {
          line-height: 1;
          vertical-align: middle;
          display: inline-block;
          border: 1px solid #c7bd29;
          border-radius: 50%;
          margin-right: 3px;
          width: 18px;
          height: 18px;
          padding: 1px;
          font-size: 12px;
          .icon {
            vertical-align: middle;
            display: inline-block;
          }
        }
      }
    }
    .star-label {
      .stars {
        cursor: pointer;
      }
    }
    .btn.btn-primary.star-label {
      .stars {
        .star {
          color: #222222;
        }
      }
    }
  }
  .route-list {
    .premium-search-table-header {
      white-space: nowrap;
      i {
        margin-left: 5px;
        vertical-align: middle;
      }
      &:hover {
        cursor: pointer;
      }
    }
    @include respond-to(xs) {
      td {
        padding: 8px 0;
      }
      .tags {
        text-align: right;
      }
    }
    .tags {
      display: inline-block;
      vertical-align: middle;
      max-width: 100px;

      .tag {
        line-height: 18px;
        vertical-align: middle;
        display: inline-block;
        border-radius: 50%;
        margin-bottom: 3px;
        margin-right: 3px;
        width: 18px;
        height: 18px;
        padding: 1px;
        font-size: 12px;
        .icon {
          vertical-align: middle;
          display: inline-block;
        }
      }
    }

    .route-block {
      &__name {
        line-height: 1.3;
        .stars.star-span {
          display: inline-block;
          font-size: 10px;
        }
      }
      &__description {
        line-height: 1.3;
        font-weight: 400;
      }
      &__properties {
        line-height: 1;
      }
      .route-property {
        margin-left: 5px;
        .tags .tag {
          overflow: visible;
        }
      }
      .flex-container {
        display: flex;
        justify-content: space-between;
      }
      .route-details {
        margin: 0;
        color: #929292;
        font-size: 12px;
      }
      @include respond-to(xs) {
      }
    }
  }
}
