// Core variables and mixins

$brand-primary: #f3dc10;

$btn-primary-color: #333 !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: darken($btn-primary-bg, 5%) !default;

$premium-tag-bg: #f3dc10;

$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee
$zindex-tooltip: 1030 !default;
$text-color: $gray-dark !default;

//$screen-sm: 0px;
//$screen-md: 0px;
$navbar-border-radius: 0;

$border-radius-large: 5px;

$input-border-focus: $brand-primary;

$font-family-oswald: "Oswald", "Arial", sans-serif;
$font-family-roboto: "Roboto", "Trebuchet Ms", "Helvetica", "Arial", sans-serif;

$font-family-sans-serif: $font-family-roboto;

$font-family-base: $font-family-sans-serif;

$link-color-yellow: #c7b411;
$link-color-blue: #1e81c7;
$text-color-gray: #414042;

$font-size-base: 14px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * .85)) !default; // ~12px

$font-size-h1: floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2: floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3: ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5: $font-size-base !default;
$font-size-h6: ceil(($font-size-base * .85)) !default; // ~12px

$border-radius-base: 4px !default;

$image_path: '../images/';

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
@mixin respond-to($media) {
  @if $media == xs {
    @media only screen and (max-width: $screen-xs-max) {
      @content;
    }
  } @else if $media == sm {
    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      @content;
    }
  } @else if $media == sm-min {
    @media only screen and (min-width: $screen-sm-min) {
      @content;
    }
  } @else if $media == sm-max {
    @media only screen and (max-width: $screen-sm-max) {
      @content;
    }
  } @else if $media == md {
    @media only screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      @content;
    }
  } @else if $media == md-min {
    @media only screen and (min-width: $screen-md-min) {
      @content;
    }
  } @else if $media == md-max {
    @media only screen and (max-width: $screen-md-max) {
      @content;
    }
  } @else if $media == lg {
    @media only screen and (min-width: $screen-lg-min) {
      @content;
    }
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
