.reqular-search {
  .reqular-search-text {
    width: 100%;
    border: none;
    border-bottom: 1px solid #d1d1d1;
    outline: none;
    box-shadow: none;
    font-size: 18px;
    font-weight: 100;
    line-height: 40px;
    border-radius: 0;
    margin: 5px 0;
    background: rgba(255, 255, 255, 0.5);
  }
  .reqular-searchbox {
    width: 100%;
    margin-top: 10px;
  }
}

.regular-search {
  .autocomplete-suggestions {
    .items-container {
      border: none;
      box-shadow: none;
      background: transparent;
    }
  }
  .autocomplete-suggestion {
    cursor: pointer;
    padding: 0;
    &:hover {
      background-color: #ddd;
    }
    .cont {
      padding: 5px 5px 0 0;
    }
  }
}
