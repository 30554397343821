.popup-container {
  &--fixed-height {
  }

  &__header {
    padding: 0 10px;
  }

  &__content {
    padding: 0 10px 10px 10px;
    overflow: auto;
    max-height: 70vh;
  }
}

.blockUI {
  background: black url("#{$image_path}blockui.gif") repeat;
  z-index: 1040;
}

.facebox {
  z-index: 1050 !important;
  border-radius: $border-radius-base;
}

.loading {
  position: fixed;
  z-index: $zindex-tooltip !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);

  .wrapper {
    position: absolute;
    margin-top: -90px;
    width: 100%;
    top: 45%;
    left: 0;
  }
}

.loader:before,
.loader:after,
.loader {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: loading 1.8s infinite ease-in-out;
  animation: loading 1.8s infinite ease-in-out;
}

.loader {
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

@-webkit-keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #ffffff;
  }
  40% {
    box-shadow: 0 2.5em 0 0 #ffffff;
  }
}

@keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #ffffff;
  }
  40% {
    box-shadow: 0 2.5em 0 0 #ffffff;
  }
}

form,
.mapbox,
.facebox {
  .nav {
    margin: 15px 0;
  }

  .slice {
    width: 100%;
    height: 250px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $font-family-roboto;
    text-transform: none;

    &.oswald {
      font-family: $font-family-roboto;
    }

    &.text-uppercase {
      text-transform: uppercase;
    }
  }

  a {
    color: $link-color-blue;
  }

  .footer {
    color: $text-color;
    background: transparent;
    margin-top: 5px;
    padding: 10px 0 0 0;
    border-top: 1px solid #ddd;
    text-align: right;

    a {
      color: $link-color-yellow;
    }

    .btn {
      color: $gray-base;
      font-weight: normal;
    }

    .button {
      display: inline-block;

      input[type="button"] {
        margin-right: 0;
      }

      input[type="submit"] {
        margin-right: 0;
      }

      & + .button {
        margin-left: 5px;
      }
    }

    &--noborder {
      border-color: transparent;
    }
  }
}

.full-screen-popup {
  z-index: 1050;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #202020;
    height: 52px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    font-size: 16px;

    .btn-back {
      color: #fff;
      cursor: pointer;
      padding: 15px;
    }

    .title {
      color: #fff;
      font-weight: 500;
    }
  }

  .content {
    position: absolute;
    top: 53px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f0f0f0;
  }

  &--hidden {
    display: none;
    opacity: 0;
  }
}

@keyframes showPopup {
  0% {
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes hidePopup {
  0% {
    display: block;
    opacity: 1;
  }
  99% {
    display: block;
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0;
  }
}
