
.timeline-item {
  margin: 0;
  position: relative;
  .flag-wrapper {
    //display: none;
  }
  .icon-wrapper  {
    display: none;
  }
  .flag {
    position: relative;
    display: block;
    padding: 10px 12px;
    background: #fff;
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    border-radius: 4px;
    text-align: left;
  }

  .content {
    .image {
      float: left;
    }
    %img.climber-thumb {
      vertical-align: top;
    }
    .message {
      margin-left: 50px;

      .climber-name {
        color: #333;
        font-weight: bold;
      }
      .date {
        color: #777777;
        * {
          color: #777777;
          text-decoration: none;
        }
      }
      .description {
        color: #333;
      }
    }

    .photo {
      margin-top: 5px;
      border: 1px solid $gray-lighter;
      max-height: 500px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
}


.feed-items.timeline {

  position: relative;
  width: 660px;
  margin: 40px auto;
  padding: 40px 0;
  list-style-type: none;

  &:before {
    position: absolute;
    left: 50%;
    top: 0;
    content: ' ';
    display: block;
    width: 6px;
    height: 100%;
    margin-left: -3px;
    $line-color-rgb: rgb(255,255,255);
    $line-color-rgba: rgba(255,255,255, 0);
    background: $line-color-rgb;
    background: -moz-linear-gradient(top, $line-color-rgba 0%, $line-color-rgb 8%, $line-color-rgb 92%, $line-color-rgba 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(30, 87, 153, 1)), color-stop(100%, rgba(125, 185, 232, 1)));
    background: -webkit-linear-gradient(top, $line-color-rgba 0%, $line-color-rgb 8%, $line-color-rgb 92%, $line-color-rgba 100%);
    background: -o-linear-gradient(top, $line-color-rgba 0%, $line-color-rgb 8%, $line-color-rgb 92%, $line-color-rgba 100%);
    background: -ms-linear-gradient(top, $line-color-rgba 0%, $line-color-rgb 8%, $line-color-rgb 92%, $line-color-rgba 100%);
    background: linear-gradient(to bottom, $line-color-rgba 0%, $line-color-rgb 8%, $line-color-rgb 92%, $line-color-rgba 100%);

    z-index: 5;
  }

  .flag-wrapper {
    position: relative;
    display: inline-block;
    text-align: center;
  }

  li {
    padding: 11px 0;
    margin: -45px 0;
    &:after {
      content: "";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }


    .timeline-item {
      margin: 0;
      position: relative;
      width: 300px;
      .flag-wrapper {
        position: relative;
        display: inline-block;
        text-align: center;
      }
      .icon-wrapper  {
        position: absolute;
        top: 50%;
        right: -43px;
        display: block;
        margin-top: -23px;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        background-color: #fff; //$brand-primary;
        padding: 4px;
        z-index: 10;
        font-size: 12px;
        text-align: center;
        border: 3px solid #F3F3F3;
        color: #666;
      }
      .flag {
        position: relative;
        display: block;
        padding: 10px 12px;
        background: #fff;
        border: 1px solid;
        border-color: #e5e6e9 #dfe0e4 #d0d1d5;
        border-radius: 4px;

        text-align: left;

      }

      .content {
        .image {
          float: left;
        }
        %img.climber-thumb {
          vertical-align: top;
        }
        .message {
          margin-left: 50px;

          .climber-name {
            color: #333;
            font-weight: bold;
          }
          .date {
            color: #777777;
          }
          .description {
            color: #333;
          }
        }
      }
    }

    &:nth-child(odd) {
      .timeline-item {
        float: left;
        text-align: right;

        .flag {
          &:after {
            content: "";
            position: absolute;
            left: 100%;
            top: 50%;
            height: 0;
            width: 0;
            margin-top: -25px;
            border: solid transparent;
            border-left-color: rgb(255,255,255);
            border-width: 8px;
            pointer-events: none;
          }
        }
      }
    }
    &:nth-child(even) {
      .timeline-item {
        float: right;
        .icon-wrapper {
          left: -43px;
        }
        .flag {
          &:after {
            content: "";
            position: absolute;
            right: 100%;
            top: 50%;
            height: 0;
            width: 0;
            margin-top: -25px;
            border: solid transparent;
            border-right-color: rgb(255,255,255);
            border-width: 8px;
            pointer-events: none;
          }
        }
      }
    }
  }
}

@mixin feed-item-time-line-left() {
  width: 100%;
  &:before {
    display: none;
    left: 0;
  }
  li {
    margin: 0px 0;
    padding: 3px 0;
    .timeline-item {
      width: auto;
      text-align: left;
      .icon-wrapper  {
        right: auto;
        left: -13px;
        display: none;
      }
      .flag-wrapper {
        display: block;
        .flag {
          margin-left: 0px;
          &:after {
            display: none;
          }
        }
      }
      .content.message {
        margin-left: 40px;
      }
    }

    &:nth-child(odd) {
      .timeline-item {
        text-align: left;
        float: none;

        .flag {
          &:after {
            margin-top: -20px;
            left: auto;
            right: 100%;
            border-left: none;
            border-right-color: rgb(255,255,255);
          }
        }
      }
    }
    &:nth-child(even) {
      .timeline-item {
        text-align: left;
        float: none;
        .icon-wrapper {
          left: -13px;
        }

        .flag {
          &:after {
            margin-top: -20px;
          }
        }
      }
    }
  }
}

@include respond-to(xs) {
  .feed-items.timeline {
    @include feed-item-time-line-left();
  }
}

.feed-items.timeline.timeline-blocks {
  @include feed-item-time-line-left();
}
