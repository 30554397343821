body.dragging, body.dragging * {
  cursor: move !important;
}

.dragged {
  position: absolute;
  opacity: 0.5;
  z-index: 2000;
}
//.plugin-sortable {
//  position: relative;
//}
.plugin-sortable li.placeholder {
  position: relative;
}
.plugin-sortable li.placeholder:before {
  position: absolute;
}
//ol.example li.placeholder {
//  position: relative;
//  /** More li styles **/
//}
//ol.example li.placeholder:before {
//  position: absolute;
//  /** Define arrowhead **/
//}