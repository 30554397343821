.dropdown {
  &--filter {
    display: inline-block;
  }
  &.open {
    .dropdown-menu--filter {
      @include respond-to(sm) {
        position: fixed;
        top: 20vh;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
      @include respond-to(xs) {
        position: fixed;
        left: 15px;
        right: 15px;
        top: 20vh;
      }
    }
  }
  .dropdown-menu--filter,
  .dropdown-menu--notifications {
    min-width: 300px;
    .dropdown-custom-container {
      padding: 0px 15px;
      line-height: 1;
      .miniheader {
        font-weight: 500;
        padding: 15px 0 5px;
        margin: 0;
        font-size: 12px;
      }
      .read-more {
        padding: 15px 0 5px;
        font-size: 12px;
        background: transparent;
        color: $link-color-blue;
        &:hover {
          background: transparent;
          text-decoration: underline;
        }
      }
      .notification-items {
        padding: 10px 0 10px 0;
        a.notification-item {
          color: #222;
          font-size: 14px;
          line-height: 1.2;
          padding: 10px 0;
          margin-bottom: 0;

          & + .notification-item {
            border-top: 1px solid #ededed;
          }
        }
        .no-items-text {
          font-size: 14px;
          font-weight: 400;
          padding: 10px 0;
        }
      }
      .checkbox {
        label {
          line-height: 1.5;
          vertical-align: middle;
        }
        input {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 8px;
        }
      }
      .close-footer {
        border-top: 1px solid #dedede;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        .close-footer-btn {
          padding: 10px;
          color: #222222;
        }
      }
    }
  }
}
