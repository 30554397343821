$switchPrefixCls: rc-switch;

$duration: .3s;

.rc-switch {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 90px;
  height: 34px;
  line-height: 34px;
  vertical-align: middle;
  border-radius: 4px 4px;
  border: 1px solid #ccc;
  background-color: #ccc;
  cursor: pointer;
  transition: all $duration cubic-bezier(0.35, 0, 0.25, 1);

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

  &-inner {
    color: #fff;
    font-size: 12px;
    position: absolute;
    left: 10px;
    right: 10px;
    text-align: right;
  }

  &:after {
    position: absolute;
    width: 18px;
    height: 30px;
    left: 2px;
    top: 1px;
    border-radius: 2px;
    background-color: #fff;
    content: " ";
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    transform: scale(1);
    transition: left $duration cubic-bezier(0.35, 0, 0.25, 1);
    animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
    animation-duration: $duration;
    animation-name: rcSwitchOff;
  }

  &:hover:after {
    transform: scale(1);
    animation-name: rcSwitchOn;
  }

  &:focus {
    box-shadow: 0 0 0 2px tint(#2db7f5, 80%);
    outline: none;
  }

  &-checked {
    border: 1px solid #87d068;
    background-color: #87d068;

    .rc-switch-inner {
      text-align: left;
    }

    &:after {
      left: 90-4-18px;
    }
  }

  &-disabled {
    cursor: no-drop;
    background: #ccc;
    border-color: #ccc;

    &:after {
      background: #9e9e9e;
      animation-name: none;
      cursor: no-drop;
    }

    &:hover:after {
      transform: scale(1);
      animation-name: none;
    }
  }

  &-label {
    display: inline-block;
    line-height: 20px;
    font-size: 14px;
    padding-left: 10px;
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    user-select: text;
  }
}

@keyframes rcSwitchOn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rcSwitchOff {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}