.summary-chart {
  width: 100%;
  height: auto;

  .bars {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .col {
    display: flex;
    flex-direction: column;
    vertical-align: bottom;
    justify-content: flex-end;
    width: 10%;
    background-color: transparent;
    height: 100%;
    vertical-align: bottom;
    text-align: center;

    & + .col {
    }

    .col__route-count {
      font-size: 10px;
    }

    .cell {
      background: #EFEFF3 !important;
      border-radius: 15px;
      display: flex;
      align-items: flex-end;
    }

    .bar {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 15px;
    }

    .bar--empty {
      display: none;
    }

    .grade-color-3 {
      background-color: #67DB67;
    }

    .grade-color-4 {
      background-color: #67DB67;
    }

    .grade-color-5 {
      background-color: #54B254;
    }

    .grade-color-6 {
      background-color: #DBC567;
    }

    .grade-color-7 {
      background-color: #DB8267;
    }

    .grade-color-8 {
      background-color: #874076;
    }

    .grade-color-9 {
      background-color: #020202;
    }

    .grade-label {
      width: 100%;
      text-align: center;
      //margin-bottom: -20px;
      font-weight: 500;
      font-size: 12px;
    }
  }

  &--simple {
    .col {
      width: 13%;

      & + .col {
      }

      .bar {
        border-radius: 15px;
      }
    }
  }

  &--map {
    .col {
      width: 19px;

      .col__route-count {
        font-size: 10px;
        line-height: 1;
        text-align: center;
        color: $gray-light;
        height: $font-size-small;
      }

      & + .col {
      }

      .bar {
        border-radius: 15px;
      }
    }
  }

  &--full {
    .col {
      & + .col {
        margin-left: 2%;
      }

      .bar {
        border-radius: 35px;
      }
    }
  }
}