.upload_watcher {
  text-align: center;
  &__done {
    display: none;
    line-height: 80px;
    font-weight: bold;
  }
  &__processing {

  }
}